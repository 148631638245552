import axios from 'axios';

let prefix = "/report"

const ReportApi = {
	
	getStats(payload){
		return axios.get( prefix + "/stats", {params: payload})
	},
}

export default ReportApi;