import axios from 'axios';

let prefix = "/investment-plan"

const InvestmentPlanApi = {
	
	listInvestmentPlan(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listIntervalRef(payload){
		return axios.get( prefix + "/interval-ref", {params: payload})
	},
	updateInvestmentPlan(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default InvestmentPlanApi;